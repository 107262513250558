/**
 * @file Describe the basic tools and values needed further
*/

const DEV_MODE = window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1" ? true : false;
const STAGE_MODE = DEV_MODE == false && window.location.hostname == "c7front.emeka.fr" ? true : false;
const PROD_MODE = DEV_MODE == false && STAGE_MODE == false ? true : false;
const current_page = typeof document != "undefined" ? document.querySelector('body')?.getAttribute('data-page') ?? document?.location?.pathname : undefined;
const locale = document.querySelector('html')?.getAttribute('locale');

const _log = function(string, force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.log(string);
}

const _warn = function(string, force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.warn(string);
}

const _trace = function(string, force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.trace(string);
}

const _error = function(string, force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.error(string);
}

const _alert = function(string, force = false) {
    (DEV_MODE || force || _mode_force(force)) && window.alert(string);
}

const _slog = function(string, style = '', force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.log("%c" + string, style);
}

const _swarn = function(string, style = '', force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.warn("%c" + string, style);
}

const _strace = function(string, style = '', force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.trace("%c" + string, style);
}

const _serror = function(string, style = '', force = false) {
    (DEV_MODE || force || _mode_force(force)) && console.error("%c" + string, style);
}

const _mode_force = function(_mode = null) {
    if(typeof _mode == "string" && _mode.length > 0) {
        switch(_mode) {
            case "dev": return DEV_MODE == true;  
            case "stage": return STAGE_MODE == true;  
            case "prod": return PROD_MODE == true;  
            default: return false;
        }
    }
    return false;
}

_log("DEV_MODE");
STAGE_MODE && _log("STAGE_MODE");
PROD_MODE && _log("PROD_MODE");